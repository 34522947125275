
@import "elements",
        "forms",
        "tables",
        "accordion";

body {
    /* DROPDOWN */
    .dropdown-menu {
        .dropdown-item {
            &.active,&:active {
                background-color: $nav_color;
            }
        }
    }
    .input-group {
        > .dropdown:first-child {
            padding: 0px;
            > button {
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
            }
        }
    }

    /* TABS */
    ul[role="tablist"].nav.nav-tabs {
        margin-bottom: 15px;
        &.wide_nav {
            border: none;
            display: flex;
            justify-content: space-between;
            > li.nav-item {
                flex-grow: 1;
                > a[role="tab"].nav-link{
                    color: $nav_color;
                    &.active::after {
                        height: 2px;
                        
                    }
                    i {
                        margin-right: .5rem;
                    }
                } 
                text-align: center;
            }
        }
        li.nav-item {
            height: 42px;
            &:focus-visible {
                outline: none;
            }
            a[role="tab"].nav-link {
                display: block;
                margin: 0px 1rem;
                padding: .5rem 0px;
                color: $dark;
                &.active {
                    position: relative;
                    background: transparent;
                    color: $nav_color;
                    border: none;
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: -2px;
                        left: -1rem;
                        width: calc(100% + 2rem);
                        height: 1px;
                        background: $nav_color;
                    }
                }
                &:hover, &:focus {
                    border-color: transparent transparent transparent;
                }
            }
        }
    }

    /* PAGINATION */
    ul.pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0px;
        li.page-item {
            &.active {
                a.page-link {
                    background: transparent;
                    color: $dark;
                }
            }
            a.page-link {
                display: block;
                border: none;
                &:empty {
                    display: none;
                }
                &:focus {
                    box-shadow: none;
                }
                .paginationForm {
                    display: flex;
                    align-items: center;
                    input {
                        width: 2.5rem;
                        height: 2rem;
                        padding: .25rem .5rem;
                        margin-right: .25rem;
                        text-align: center;
                    }
                }
            }
        }
    }

    /* TOASTS */
    .toast-container {
        .toast {
            &.bg-success, &.bg-danger, &.bg-warning {
                padding: 0px .25rem;
                .toast-header {
                    background-color: transparent;
                    color: #212529;
                    font-weight: 500;
                    border-bottom-color: $thin_gray;
                    > button.btn-close {
                        width: .75em;
                        height: .75em;
                        margin-left: auto;
                        background: url( svg-encode("<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#fff'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>") );
                    }
                }
            }
            &.bg-success, &.bg-danger {
                .toast-header {
                    color: $thin_gray;
                }
            }
        }
    }

    /* BADGE */
    span.badge {
        &.brand_light, &.brand_default {
            margin-right: .5rem;
            font-weight: 400;
            line-height: 1rem;
            &:last-of-type {
                margin-right: 0px;
            }
            > button.close_badge {
                padding: 0px;
                line-height: 1rem;
                > span {
                    font-size: .75rem;
                }
            }
        }
        &.brand_light {
            background: map-get($palette, "thin_color");
            color: map-get($palette, "light_color");
            > button.close_badge {
                > span {
                    color: $gray;
                }
            }
        }
        &.brand_default {
            background: map-get($palette, "light_color");
            color: #fff;
            > button.close_badge {
                > span {
                    color: map-get($palette, "thin_color");
                }
            }
        }
    }

    /* PROGRESS BAR */
    .progress {
        background: map-get($palette, "thin_color");
        border-radius: 0px;
        overflow: unset;
        .progress-bar {
            position: relative;
            background: $nav_color;
            overflow: unset;
            > span {
                position: absolute;
                top: 100%;
                right: 0px;
                width: 24px;
                display: inline-block;
                margin-right: -12px;
                color: $nav_color;
                font-size: .75rem;
                font-weight: 500;
            }
        }
    }

    /* NGX SLIDER */
	.ngx-slider {
		margin: 15px 0 30px;
		.ngx-slider-pointer {
			width: 10px;
			height: 10px;
			top: -4px;
            background-color: $nav_color;
			&:after {
				display: none;
            }
            &:focus{
                outline: none;
            }
		}
		.ngx-slider-bubble {
            bottom: unset;
            top: 10px;
            color: $nav_color;
        }
        .ngx-slider-bar {
			height: 2px;
        }
        .ngx-slider-selection {
            background-color: $nav_color;
		}
	}

}
