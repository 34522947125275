ngb-accordion.accordion {
    .accordion-item {
        border-left: none;
        border-right: none;
        .accordion-header{
            button.accordion-button{
                background: white;
                color: unset;
                font-weight: 500;
                box-shadow: none;
                &:focus{
                    box-shadow: none;
                }
                &::after{
                    content: none;
                }
                &::before{
                    content: '\f107';
                    font-weight: 600;
                    font-family: "Font Awesome 5 Pro", sans-serif;
                    transition: transform .25s ease-in-out;
                    margin-right: 1rem;
                }
                &.collapsed::before{
                    transform: rotate(-90deg);
                }
            }
        }
        .accordion-body{
            padding-top: 0;
        }
    }
}
