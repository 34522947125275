
body {
    button {
        &:focus {
            outline: none;
            box-shadow: none;
        }
        &.btn, &.btn-primary, &.btn-secondary, &.btn-light {
            &:focus {
                outline: none;
                box-shadow: none;
            }
        }
    }
    input[type="number"] {
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }
    textarea {
        resize: none;
    }
    label {
        &.btn, &.btn-primary, &.btn-secondary, &.btn-light {
            &.focus {
                box-shadow: none;
            }
        }
    }
    .form-control, .form-select {
        &:focus {
            box-shadow: none;
        }
        &.flat_control{
            border-top: 0px;
            border-right: 0px;
            border-left: 0px;
            border-radius: 0px;
        }
    }
    .form-select {
        &.custom_select_dropdown{
            width: 100%;
            text-align: left;
            display:flex;
            >span{
                @include TextEllipsis();
            }
            &::after{
                display: none;
            }
            +.dropdown-menu{
                min-width: 100%;
                max-height: 25.75em;
                overflow-y: auto;
            }
           
        }
    }

    /* FILE INPUT */
    .form-control[type="file"] {
        height: calc(2em + .75rem + 2px);
        opacity: 0;
        z-index: 2;
    }
    .form-control[type="file"] ~ .form-label {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        padding: .375rem .75rem;
        margin-bottom: 0px;
        border: 1px dashed $light_gray;
        z-index: 1;
    }

    /* CHECKBOXES & RADIOS */
    .form-check {
        @include CustomCheckbox();
        @include CustomRadio();
    }

    /* SWITCHES */
    .form-check.form-switch {
        @include CustomSwitch();
    }

    .form-floating{
        @include CustomFloatingLabel()
    }
}

