
@import "functions",
        "typography",
        "branded";

@mixin CustomScroll($scrollbar_color: $nav_color) {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    &::-webkit-scrollbar-track {
        background: $thin_gray;
    }
    &::-webkit-scrollbar-thumb {
        background: $scrollbar_color;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        border-bottom-right-radius: 50px;
        border-bottom-left-radius: 50px;
    }
}

@mixin ImportExportSpinner($color: $nav_color) {
    .export_progress , .import_progress {
        color: $color;
        > span.progress_spinner {
            position: relative;
            display: inline-block;
            min-width: 20px;
            vertical-align: middle;
            .spinner_content {
                i.spinner_icon {
                    color: $color;
                }
            }
        }
    }
    .import_progress {
        display: inline-block;
        margin-bottom: 0px;
    }
}

@mixin EllipsesSlider() {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    &.ellipses {
        &:not(:hover) {
            text-overflow: ellipsis;
        }
        &:hover {
            width: fit-content;
            animation-name: scroll-text;
            animation-duration: 5s;
            animation-timing-function: linear;
            animation-delay: 0s;
            animation-iteration-count: infinite;
            animation-direction: normal;
        }
    }
}

@keyframes scroll-text {
    0% {
        transform: translateX(0%);
    }
    25% {
        transform: translateX(-33.333%);
    }
    50% {
        transform: translateX(-66.666%);
    }
    75% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0%);
    }
}

@mixin IconGroup($icon_type, $icon_size: 35px) {
    .icon_group {
        position: relative;
        display: inline-block;
        margin-bottom: .5rem;
        margin-right: .5rem;
        &:last-of-type {
            margin-right: 0px;
        }
        input {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            box-sizing: border-box;
            cursor: pointer;
        }
        label.field_icon {
            width: $icon_size;
            height: $icon_size;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0px;
            border-radius: 100%;
            z-index: -1;
            i {
                @if $icon_type == 'image' {
                    width: 100%;
                    height: 100%;
                } @else if $icon_type == 'font' {
                    font-size: 1.25rem;
                }
                @content;
            }
        }
        input:checked ~ label.field_icon {
            background: rgba(transparent, .1);
        }
        input:disabled ~ label.field_icon {
            color: $light_gray;
        }
    }
}

@mixin DynamicFields($color_theme: map-get($palette, "light_color")) {
    .dynamic_fields {
        padding: 0px 15px;
        border: 1px solid $light_gray;
        &.wide_fields {
            .fields_body {
                padding: 0px;
            }
        }
        &.table_fields {
            .fields_head {
                text-align: left;
            }
        }
        .fields_head, .fields_body {
            margin-left: -15px;
            margin-right: -15px;
            padding-left: 15px;
            padding-right: 15px;
        }
        .fields_head {
            background: $light_gray;
            margin-bottom: 0px;
            padding-top: 15px;
            padding-bottom: 15px;
            text-align: right;
            font-size: .75rem;
            font-weight: 400;
            color: $gray;
            .head_action {
                color: $gray;
            }
        }
        .fields_body {
            max-height: 300px;
            padding-top: 15px;
            padding-bottom: 15px;
            overflow-y: auto;
            overflow-x: hidden;
            @include CustomScroll($color_theme);
            .field_selected {
                background: $thin_gray;
            }
            .add_field {
                color: $color_theme;
            }
        }
    }
}

@mixin DefaultShadow {
    box-shadow: -1px 1px 3px $light_gray;
}

@mixin TextEllipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin InputCopy {
    .input_copy {
        position: relative;
        input {
            padding-right: 35px;
        }
        .fa-copy {
            position: absolute;
            top: 42px;
            right: 15px;
            cursor: pointer;
        }
    }
}

@mixin KebabMenu {
    .kebab_menu {
        display: inline-block;
        > button {
            padding-left: 0px;
            padding-right: 0px;
            &:after {
                content: none;
            }
        }
    }
}

@mixin FiltersMenu {
    .filters_menu {
        > button {
            &:after {
                content: none;
            }
        }
        > div[ngbDropdownMenu] {
            width: 300px;
            padding: .5rem;
        }
    }
}

@mixin boardGrid($size) {
    background: repeating-linear-gradient(to right, transparent 0  calc(#{$size} - 1px), #F1F2F7 calc(#{$size} - 1px) $size),
    repeating-linear-gradient(to bottom, transparent 0 calc(#{$size} - 1px), #F1F2F7 calc(#{$size} - 1px) $size) #fff;
}

@mixin interactionBadge() {
    background: $light_gray;
    display: inline-block;
    margin: 0px 5px 5px 0px;
    padding: .5rem 1rem;
    color: $gray;
    border-radius: 25px;
    &:last-of-type {
        margin-right: 0px;
    }
}