
@mixin CustomCheckbox($checkbox_color: $form_elements_color) {
    .form-check-input[type="checkbox"]:checked,.form-check-input[type="checkbox"]:indeterminate {
        background-color: $checkbox_color;
        border-color: $checkbox_color;
    }
}

@mixin CustomRadio($radio_color: $form_elements_color) {
    .form-check-input[type="radio"]:checked {
        background-color: $radio_color;
        border-color: $radio_color;        
    }
}

@mixin CustomSwitch($switch_color: $form_elements_color) {
    .form-check-input {
        background-image: url( svg-encode("<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#fff'/></svg>") );
        background-color: $light_gray;
        border: none;
        &:checked {
            background-image: url( svg-encode("<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$switch_color}'/></svg>") );
            background-color: lighten($switch_color, 25);
        }
    }
}

@mixin CustomFloatingLabel(){
    >label{
        opacity: 1 !important;
        color: $gray;
    }

}
