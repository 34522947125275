
body {
    table.table {
        > :not(:first-child) {
            border-color: $light_gray;
        }
        thead {
            tr {
                &:hover {
                    th .form-check {
                        opacity: 1;
                    }
                }
                th {
                    border-top: none;
                    white-space: nowrap;
                    &.column_sortable {
                        cursor: pointer;
                        &:before {
                            content: "\f0dc";
                        }
                        &:before, &.asc_sorted:before, &.desc_sorted:before {
                            margin-right: .25rem;
                            font-family: "Font Awesome 5 Free", sans-serif;
                        }
                        &.asc_sorted {
                            &:before {
                                content: "\f0de";
                            }
                        }
                        &.desc_sorted {
                            &:before {
                                content: "\f0dd";
                            }
                        }
                    }
                }
            }
        }
        tbody {
            tr {
                &:hover {
                    td {
                        &.action_column {
                            .alternating_hover {
                                .hover_visible {
                                    order: 1;
                                }
                                .hover_hidden {
                                    order: 2;
                                }
                            }
                            button {
                                opacity: 1;
                                &:hover {
                                    background: rgba(transparent, .1);
                                    i {
                                        color: #000;
                                    }
                                }
                            }
                            span {
                                opacity: 0;
                            }
                        }
                        &.action_column_sm {
                            button {
                                opacity: 1;
                                &:hover {
                                    i {
                                        color: #000;
                                    }
                                }
                            }
                        }
                    }
                }
                td {
                    &.action_column {
                        .alternating_hover {
                            display: flex;
                            align-items: center;
                            .hover_visible {
                                order: 2;
                            }
                            .hover_hidden {
                                order: 1;
                            }
                        }
                        button {
                            width: 46px;
                            height: 46px;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            padding: 0px;
                            opacity: 0;
                            border-radius: 100%;
                            i {
                                transition: color .25s linear 0s;
                            }
                        }
                        span {
                            display: inline-block;
                            justify-content: center;
                            width: 46px;
                            text-align: center;
                            opacity: 1;
                            > i {
                                font-size: 1.25rem;
                            }
                        }
                    }
                    &.action_column_sm {
                        text-align: center;
                        button {
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            padding: 0px;
                            opacity: 0;
                            i {
                                transition: color .25s linear 0s;
                            }
                        }
                    }
                }
            }
        }
        tr:hover .selection_column, tr .selection_column.active_selection {
            .form-check{
                opacity: 1;
            }
            
        }
       
        th,td{
            &.selection_column {
                .form-check{
                    opacity: 0;
                    transition: opacity .25s linear 0s;
                }
            }
        }
    }
}
